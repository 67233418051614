import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import IntroBlock from "../components/blocks/IntroBlock"
import EmailSubscribe from "../components/EmailSubscribe"

const Changelog = ({ data }) => {
  const changelogInfo = data.changelogInfo.edges
  const updates = data.updates.edges
  return (
    <div className="changelog-page">
      <Layout>
        <div className="main-col-one">
          <Seo title={changelogInfo[0].node.intro.title} />

          <IntroBlock content={changelogInfo[0].node.intro} />

          <EmailSubscribe
            title="Subscribe to changelog updates"
            target=""
            id="changelog-subscribe"
            changelogSubscriber={true}
            changelog={true}
            status={false}
          />

          {updates.map((update, key) => {
            const fullDate = update.node.published_at
            console.log(fullDate)
            const date = new Date(fullDate.split("T")[0])
            console.log(date)
            const day = date.getDate()
            console.log(day)
            var month = [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ][date.getMonth()]
            const dateStr = day + " " + month + " " + date.getFullYear()
            return (
              <div className="update-wrap" key={key}>
                <div className="date">{dateStr}</div>
                <div className="midline"></div>
                <div className="update">
                  <div className="title">{update.node.title}</div>
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: update.node.description,
                    }}
                  ></div>
                </div>
              </div>
            )
          })}
        </div>
      </Layout>
    </div>
  )
}

export default Changelog

export const pageQuery = graphql`
  {
    changelogInfo: allStrapiChangelog {
      edges {
        node {
          intro {
            title
            subtitle
            introduction {
              content
            }
          }
        }
      }
    }
    updates: allStrapiUpdate(sort: { order: DESC, fields: published_at }) {
      edges {
        node {
          title
          description
          published_at
        }
      }
    }
  }
`
